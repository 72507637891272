export const fontSize = 14
export const htmlFontSize = 14
export const fonts = {
	plusJakartaSans: {
		fontFamily: '"PlusJakartaSans"',
		fontWeight: 400,
	},
	plusJakartaSansLight: {
		fontFamily: '"PlusJakartaSans-Light"',
		fontWeight: 300,
	},
	plusJakartaSansMedium: {
		fontFamily: '"PlusJakartaSans-Medium"',
		fontWeight: 500,
	},
	plusJakartaSansSemiBold: {
		fontFamily: '"PlusJakartaSans-SemiBold"',
		fontWeight: 600,
	},
	plusJakartaSansBold: {
		fontFamily: '"PlusJakartaSans-Bold"',
		fontWeight: 700,
	},
	plusJakartaSansExtraBold: {
		fontFamily: '"PlusJakartaSans-ExtraBold"',
		fontWeight: 800,
	},
}
