const Form = {
	MuiFormHelperText: {
		styleOverrides: {
			root: {
				fontSize: 'var(--size-small)',
				marginLeft: 0,
				marginRight: 0,
				marginTop: '1px',
				marginBottom: '5px',
			},
		},
	},
}

export default Form
