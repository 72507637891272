const List = {
	MuiMenu: {
		styleOverrides: {
			list: {
				borderRadius: 0,
			},
		},
	},
	MuiMenuItem: {
		styleOverrides: {
			root: {
				padding: 'var(--size-8)',
				minHeight: 'unset',
			},
		},
	},
}

export default List
