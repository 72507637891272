import BHBreadcrumbs from '@/theme/components/bh-breadcrumbs'
import BHTileBase from '@/theme/components/bh-tile-base'
import BHTileTooltip from '@/theme/components/bh-tile-tooltip'
import BHTileSlide from '@/theme/components/bh-tile-slide'
import BHUpsells from '@/theme/components/bh-upsells'
import defaultTheme from '@bluheadless/ui/src/theme/default'
import { createTheme as createMuiTheme } from '@mui/material/styles' // keep @mui/material/styles to use default theme of @mui/material
import { createTheme } from '@mui/system'
import Accordion from './theme/components/accordion'
import BHNewsletter from './theme/components/bh-newsletter'
import Button from './theme/components/button'
import Chip from './theme/components/chip'
import CssBaseline from './theme/components/css-baseline'
import Dialog from './theme/components/dialog'
import BHInput from './theme/components/form/bh-input'
import Form from './theme/components/form/form'
import Input from './theme/components/form/input'
import List from './theme/components/list'
import Paper from './theme/components/paper'
import SvgIcon from './theme/components/svg-icon'
import { fonts } from './theme/fonts'

const htmlFontSize = 14
const fontSize = 14
const muiTheme = createMuiTheme({ typography: { htmlFontSize, fontSize } })

const fontFamily = fonts.plusJakartaSans.fontFamily
const fontFamilyAlternative = fontFamily

const theme = createTheme(defaultTheme, {
	// Insert here project related theme config
	palette: {
		common: {
			black: '#000000',
			white: '#ffffff',
		},
		primary: {
			main: '#000000',
			light: '#333333',
			dark: '#000000',
			contrastText: '#FFFFFF',
		},
		secondary: {
			main: '#ffffff',
			light: '#fcfcfc',
			dark: '#fcfcfc',
			contrastText: '#000000',
		},
		grey: {
			main: '#757575',
			input: '#757575',
			border: '#EAEAEA',
			label: '#AFAFAF',
			light: '#959595',
			breadcrumbs: '#9F9F9F',
			vat: '#A0A0A0',
			disabled: '#A8A8A8',
		},
		footer: {
			bgTop: '#FFFFFF',
			bgMiddleTop: '#FFFFFF',
			bgMiddle: '#FFFFFF',
			bgMiddleBottom: '#FFFFFF',
			bgBottom: '#FFFFFF',
			border: '#ECECEC',
			color: '#000',
			copyright: '#000',
		},
		background: {
			paper: '#FFFFFF',
			default: '#FFFFFF',
			header: '#FFFFFF',
			card: '#F6F6F6',
		},
		tile: {
			background: '#ffffff',
		},
		button: {
			disabled: '#E1E1E1',
		},
	},
	typography: {
		fontFamily,
		fontFamilyAlternative,
		htmlFontSize,
		fontSize,
		fontWeight: 400,
		fontWeightLight: 300,
		fontWeightMedium: 500,
		fontWeightSemiBold: 600,
		fontWeightBold: 700,
		fontWeightExtraBold: 800,
		lineHeight: '16px',
		headlineBig: {
			...fonts.plusJakartaSans,
			fontSize: muiTheme.typography.pxToRem(28),
			lineHeight: '40px',
			fontWeight: 300,
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.md}px)`]: {
				fontSize: muiTheme.typography.pxToRem(32),
				lineHeight: '40px',
				fontWeight: 400,
			},
		},
		headlineMedium: {
			...fonts.plusJakartaSansLight,
			fontSize: muiTheme.typography.pxToRem(22),
			lineHeight: '32px',
			fontWeight: 300,
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.md}px)`]: {
				fontSize: muiTheme.typography.pxToRem(26),
				lineHeight: '40px',
			},
		},
		headlinesmall: {
			...fonts.plusJakartaSans,
			fontSize: muiTheme.typography.pxToRem(16),
			lineHeight: '28px',
			fontWeight: 300,
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.md}px)`]: {
				fontSize: muiTheme.typography.pxToRem(20),
				lineHeight: '32px',
			},
		},
		subheadline1: {
			...fonts.plusJakartaSans,
			fontSize: muiTheme.typography.pxToRem(12),
			lineHeight: '24px',
			fontWeight: 400,
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.md}px)`]: {
				fontSize: muiTheme.typography.pxToRem(14),
			},
		},
		subheadline2: {
			...fonts.plusJakartaSans,
			fontSize: muiTheme.typography.pxToRem(14),
			lineHeight: '22px',
			fontWeight: 400,
		},
		headlinedescription: {
			...fonts.plusJakartaSans,
			fontSize: muiTheme.typography.pxToRem(14),
			lineHeight: '24px',
		},
		h1: {
			...fonts.plusJakartaSans,
			fontSize: muiTheme.typography.pxToRem(24),
			lineHeight: muiTheme.typography.pxToRem(40),
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.md}px)`]: {
				fontSize: muiTheme.typography.pxToRem(32),
				lineHeight: muiTheme.typography.pxToRem(40),
			},
		},
		h2: {
			...fonts.plusJakartaSans,
			fontSize: muiTheme.typography.pxToRem(20),
			lineHeight: muiTheme.typography.pxToRem(40),
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.lg}px)`]: {
				fontSize: muiTheme.typography.pxToRem(26),
				lineHeight: muiTheme.typography.pxToRem(40),
			},
		},
		h3: {
			...fonts.plusJakartaSans,
			fontSize: muiTheme.typography.pxToRem(22),
			lineHeight: '40px',
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.lg}px)`]: {
				fontSize: muiTheme.typography.pxToRem(22),
				lineHeight: '40px',
			},
		},
		h4: {
			...fonts.plusJakartaSansBold,
			fontSize: muiTheme.typography.pxToRem(16),
			lineHeight: 1,
			textTransform: 'uppercase',
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.lg}px)`]: {
				fontSize: muiTheme.typography.pxToRem(16), // override BluHeadless
			},
		},
		h5: {
			...fonts.plusJakartaSans,
			fontSize: muiTheme.typography.pxToRem(18),
			lineHeight: '24px',
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.lg}px)`]: {
				fontSize: muiTheme.typography.pxToRem(18), // override BluHeadless
				lineHeight: '24px',
			},
		},
		h6: {
			...fonts.plusJakartaSansSemiBold,
			fontSize: muiTheme.typography.pxToRem(16),
			lineHeight: 1,
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.lg}px)`]: {
				fontSize: muiTheme.typography.pxToRem(16), // override BluHeadless
				lineHeight: 1,
			},
		},
		body1: {
			...fonts.plusJakartaSans,
			fontSize: muiTheme.typography.pxToRem(12),
			lineHeight: '24px',
			fontWeight: 500,
		},
		body2: {
			...fonts.plusJakartaSansSemiBold,
			fontSize: muiTheme.typography.pxToRem(14),
			lineHeight: '24px',
		},
		body3: {
			...fonts.plusJakartaSansMedium,
			fontSize: muiTheme.typography.pxToRem(14),
			lineHeight: '40px',
			fontWeight: 500,
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.md}px)`]: {
				fontSize: muiTheme.typography.pxToRem(16),
			},
		},
		button: {
			...fonts.plusJakartaSans,
			fontSize: muiTheme.typography.pxToRem(14),
			lineHeight: '16px',
			textTransform: 'uppercase',
		},
		copyright: {
			...fonts.plusJakartaSans,
			fontSize: muiTheme.typography.pxToRem(12),
			lineHeight: '24px',
			fontWeight: 400,
			textAlign: 'center',
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.md}px)`]: {
				textAlign: 'left',
			},
		},
		caption: {
			...fonts.plusJakartaSans,
		},
		overline: {
			...fonts.plusJakartaSans,
		},
		subtitle1: {
			...fonts.plusJakartaSansSemiBold,
			fontSize: muiTheme.typography.pxToRem(12),
			lineHeight: '24px',
			letterSpacing: '10%',
			fontWeight: 600,
			textTransform: 'uppercase',
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.md}px)`]: {
				fontSize: muiTheme.typography.pxToRem(12),
			},
		},
	},
	shape: {
		borderRadius: 0,
	},
	scrollbar: `
		overflow-y: auto;
		scrollbar-width: thin;
		scrollbar-color: var(--color-brand) #F7F7F7; // thumb, track
		::-webkit-scrollbar {
			width: 7px;
			height: 5px;
		}
		//track
		::-webkit-scrollbar-track {
			background: #F7F7F7; // grey.lighter
			border-radius: 3.5px;
		}
		//handle
		::-webkit-scrollbar-thumb {
			background: var(--color-brand);
			border-radius: 3.5px;
		}
		//handle on hover
		::-webkit-scrollbar-thumb:hover {
			background: #373737; // grey.dark
		}
	`,
	scrollbarJsx: {
		scrollbarWidth: 'auto',
		scrollbarColor: 'var(--color-brand) #F7F7F7', // thumb, track
		'::-webkit-scrollbar': {
			width: 7,
		},
		//track
		'::-webkit-scrollbar-track': {
			background: '#F7F7F7', // grey.lighter
			borderRadius: 3.5,
		},
		//handle
		'::-webkit-scrollbar-thumb': {
			background: 'var(--color-brand)',
			borderRadius: 3.5,
		},
		//handle on hover
		'::-webkit-scrollbar-thumb:hover': {
			background: '#373737', // grey.dark
		},
	},
	components: {
		...Accordion,
		...BHBreadcrumbs,
		...BHInput,
		...BHNewsletter,
		...BHTileBase,
		...BHTileTooltip,
		...BHTileSlide,
		...BHUpsells,
		...Button,
		...Chip,
		...CssBaseline,
		...Dialog,
		...Form,
		...Input,
		...List,
		...Paper,
		...SvgIcon,
	},
})
export default theme
