const BHTileSlide = {
	BHTileSlide: {
		defaultProps: {
			wishListProps: {
				position: 'inner',
				variant: 'icon',
			},
			title1Props: {
				component: 'h4',
			},
			title2Props: {
				component: 'div',
			},
		},
	},
}

export default BHTileSlide
