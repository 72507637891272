const Paper = {
	MuiPaper: {
		defaultProps: {
			square: true,
			elevation: 1,
			variant: 'elevation',
		},
		styleOverrides: {
			root: {
				'.MuiIconButton-root.iconClose': {
					top: 16,
					right: 16,
					padding: 0,
				},
			},
		},
	},
}
export default Paper
