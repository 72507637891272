/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const SearchIconSvg = forwardRef((props, svgRef) => (
	<svg
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M11 2.5a8.5 8.5 0 105.646 14.854l4 4a.5.5 0 00.708-.708l-4-4A8.5 8.5 0 0011 2.5zM3.5 11a7.5 7.5 0 1112.81 5.296A7.5 7.5 0 013.5 11z"
			fill="currentColor"
		/>
	</svg>
))
SearchIconSvg.displayName = 'SearchIconSvg'

const SearchIcon = forwardRef((props, ref) => <Icon component={SearchIconSvg} ref={ref} {...props} />)
SearchIcon.displayName = 'SearchIcon'

SearchIcon.defaultProps = {
	...Icon.defaultProps,
}
SearchIcon.propTypes = {
	...Icon.propTypes,
}

export default SearchIcon
export { SearchIconSvg }
